.mail-cloud-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.mail-cloud-container01 {
  width: 100%;
  height: 500px;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  background-image: url("/curve%20line.svg");
}
.mail-cloud-container02 {
  gap: 40px;
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 60px;
  padding-right: 60px;
  flex-direction: row;
  justify-content: center;
}
.mail-cloud-container03 {
  gap: 35px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.mail-cloud-container04 {
  gap: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.mail-cloud-text {
  color: var(--dl-color-primary-lila);
  font-style: normal;
  font-weight: 500;
}
.mail-cloud-text01 {
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
}
.mail-cloud-container05 {
  flex: 0 0 auto;
  width: 200px;
  cursor: pointer;
  height: 40px;
  display: flex;
  transition: 0.1s;
  align-items: center;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  padding-left: 20px;
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: 20px;
  justify-content: center;
  background-image: linear-gradient(35deg, rgb(104, 64, 165) 1.00%,rgb(148, 84, 224) 39.00%,rgb(204, 56, 207) 100.00%);
}
.mail-cloud-container05:hover {
  width: 220px;
}
.mail-cloud-container06 {
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.mail-cloud-container07 {
  width: 100%;
  height: 400px;
  display: none;
  opacity: 0.7;
  align-items: center;
  border-color: rgba(153, 153, 153, 0.2);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: row;
  justify-content: center;
  background-color: #fdfdfd;
}
.mail-cloud-image {
  width: 200px;
  object-fit: cover;
}
.mail-cloud-container08 {
  width: 100%;
  height: 20px;
  display: flex;
  opacity: 0.3;
  box-shadow: 5px -5px 10px 0px #bfd5d8;
  align-items: flex-start;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  background-color: var(--dl-color-primary-hell);
  border-left-width: 0px;
  border-right-width: 0px;
}
.mail-cloud-container09 {
  width: 100%;
  height: 450px;
  display: flex;
  position: relative;
  align-items: center;
  padding-top: 40px;
  padding-left: 120px;
  padding-right: 120px;
  padding-bottom: 40px;
}
.mail-cloud-container10 {
  gap: 20px;
  flex: 1;
  width: 200px;
  height: 100%;
  display: flex;
  align-items: flex-start;
  padding-right: 40px;
  flex-direction: column;
}
.mail-cloud-container11 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.mail-cloud-text04 {
  color: var(--dl-color-primary-lila);
  font-style: normal;
  font-weight: 500;
}
.mail-cloud-text05 {
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
}
.mail-cloud-text06 {
  opacity: 0.7;
}
.mail-cloud-container12 {
  gap: 20px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
}
.mail-cloud-container13 {
  fill: var(--dl-color-gray-white);
  flex: 0 0 auto;
  width: 20px;
  height: 20px;
  display: flex;
  padding: 4px;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  background-color: var(--dl-color-primary-lila);
}
.mail-cloud-icon {
  width: 100%;
  height: 100%;
}
.mail-cloud-container14 {
  gap: 5px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.mail-cloud-text10 {
  font-size: 16px;
}
.mail-cloud-container15 {
  gap: 20px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
}
.mail-cloud-container16 {
  fill: var(--dl-color-gray-white);
  flex: 0 0 auto;
  width: 20px;
  height: 20px;
  display: flex;
  padding: 4px;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  background-color: var(--dl-color-primary-lila);
}
.mail-cloud-icon02 {
  width: 100%;
  height: 100%;
}
.mail-cloud-container17 {
  gap: 5px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.mail-cloud-text11 {
  font-size: 16px;
}
.mail-cloud-container18 {
  gap: 20px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
}
.mail-cloud-container19 {
  fill: var(--dl-color-gray-white);
  flex: 0 0 auto;
  width: 20px;
  height: 20px;
  display: flex;
  padding: 4px;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  background-color: var(--dl-color-primary-lila);
}
.mail-cloud-icon04 {
  width: 100%;
  height: 100%;
}
.mail-cloud-container20 {
  gap: 5px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.mail-cloud-text12 {
  font-size: 16px;
}
.mail-cloud-container21 {
  gap: 20px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
}
.mail-cloud-container22 {
  fill: var(--dl-color-gray-white);
  flex: 0 0 auto;
  width: 20px;
  height: 20px;
  display: flex;
  padding: 4px;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  background-color: var(--dl-color-primary-lila);
}
.mail-cloud-icon06 {
  width: 100%;
  height: 100%;
}
.mail-cloud-container23 {
  gap: 5px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.mail-cloud-text13 {
  font-size: 16px;
}
.mail-cloud-container24 {
  flex: 1;
  width: 200px;
  height: 100%;
  display: flex;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  justify-content: center;
}
.mail-cloud-image1 {
  width: auto;
  height: 100%;
  object-fit: cover;
}
.mail-cloud-container25 {
  width: 100%;
  height: 450px;
  display: flex;
  align-items: center;
  padding-top: 40px;
  padding-left: 120px;
  padding-right: 120px;
  padding-bottom: 40px;
}
.mail-cloud-container26 {
  flex: 1;
  width: 200px;
  height: 100%;
  display: flex;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  justify-content: center;
}
.mail-cloud-image2 {
  width: auto;
  height: 100%;
  object-fit: cover;
}
.mail-cloud-container27 {
  gap: 20px;
  flex: 1;
  width: 200px;
  height: 100%;
  display: flex;
  align-items: flex-start;
  padding-left: 40px;
  flex-direction: column;
}
.mail-cloud-container28 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.mail-cloud-text14 {
  color: var(--dl-color-primary-lila);
  font-style: normal;
  font-weight: 500;
}
.mail-cloud-text15 {
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
}
.mail-cloud-text16 {
  opacity: 0.7;
}
.mail-cloud-container29 {
  width: 100%;
  height: 450px;
  display: flex;
  position: relative;
  align-items: center;
  padding-top: 40px;
  padding-left: 120px;
  padding-right: 120px;
  padding-bottom: 40px;
}
.mail-cloud-container30 {
  gap: 20px;
  flex: 1;
  width: 200px;
  height: 100%;
  display: flex;
  align-items: flex-start;
  padding-right: 40px;
  flex-direction: column;
}
.mail-cloud-container31 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.mail-cloud-text17 {
  color: var(--dl-color-primary-lila);
  font-style: normal;
  font-weight: 500;
}
.mail-cloud-text18 {
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
}
.mail-cloud-text22 {
  opacity: 0.7;
}
.mail-cloud-container32 {
  gap: 20px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
}
.mail-cloud-container33 {
  fill: var(--dl-color-gray-white);
  flex: 0 0 auto;
  width: 20px;
  height: 20px;
  display: flex;
  padding: 4px;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  background-color: var(--dl-color-primary-lila);
}
.mail-cloud-icon08 {
  width: 100%;
  height: 100%;
}
.mail-cloud-container34 {
  gap: 5px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.mail-cloud-text26 {
  font-size: 16px;
}
.mail-cloud-container35 {
  gap: 20px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
}
.mail-cloud-container36 {
  fill: var(--dl-color-gray-white);
  flex: 0 0 auto;
  width: 20px;
  height: 20px;
  display: flex;
  padding: 4px;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  background-color: var(--dl-color-primary-lila);
}
.mail-cloud-icon10 {
  width: 100%;
  height: 100%;
}
.mail-cloud-container37 {
  gap: 5px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.mail-cloud-text27 {
  font-size: 16px;
}
.mail-cloud-container38 {
  gap: 20px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
}
.mail-cloud-container39 {
  fill: var(--dl-color-gray-white);
  flex: 0 0 auto;
  width: 20px;
  height: 20px;
  display: flex;
  padding: 4px;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  background-color: var(--dl-color-primary-lila);
}
.mail-cloud-icon12 {
  width: 100%;
  height: 100%;
}
.mail-cloud-container40 {
  gap: 5px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.mail-cloud-text28 {
  font-size: 16px;
}
.mail-cloud-container41 {
  gap: 20px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
}
.mail-cloud-container42 {
  fill: var(--dl-color-gray-white);
  flex: 0 0 auto;
  width: 20px;
  height: 20px;
  display: flex;
  padding: 4px;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  background-color: var(--dl-color-primary-lila);
}
.mail-cloud-icon14 {
  width: 100%;
  height: 100%;
}
.mail-cloud-container43 {
  gap: 5px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.mail-cloud-text29 {
  font-size: 16px;
}
.mail-cloud-container44 {
  flex: 1;
  width: 200px;
  height: 100%;
  display: flex;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  justify-content: center;
}
.mail-cloud-image3 {
  width: auto;
  height: 100%;
  object-fit: cover;
}
.mail-cloud-container45 {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 40px;
  padding-left: 120px;
  padding-right: 120px;
  padding-bottom: 40px;
  justify-content: center;
}
.mail-cloud-container46 {
  gap: 30px;
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  padding-top: 40px;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  padding-left: 20px;
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: 20px;
  flex-direction: column;
  padding-bottom: 20px;
  background-size: cover;
  background-image: url("/world%20map.svg");
}
.mail-cloud-container47 {
  gap: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.mail-cloud-text30 {
  color: var(--dl-color-primary-lila);
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
}
.mail-cloud-container48 {
  gap: 40px;
  flex: 0 0 auto;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mail-cloud-container49 {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.mail-cloud-text35 {
  font-size: 30px;
}
.mail-cloud-container50 {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.mail-cloud-text37 {
  font-size: 30px;
}
.mail-cloud-container51 {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.mail-cloud-text41 {
  font-size: 30px;
}
.mail-cloud-container52 {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.mail-cloud-text43 {
  font-size: 30px;
}
.mail-cloud-container53 {
  gap: 30px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 40px;
  flex-direction: column;
  padding-bottom: 40px;
  justify-content: center;
}
.mail-cloud-container54 {
  gap: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.mail-cloud-text45 {
  font-size: 30px;
  font-style: normal;
  text-align: center;
  font-weight: 600;
}
.mail-cloud-text46 {
  text-align: center;
}
.mail-cloud-container55 {
  gap: 40px;
  width: 100%;
  height: 300px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.mail-cloud-container56 {
  gap: 20px;
  flex: 0 0 auto;
  width: 346px;
  height: 100%;
  display: flex;
  padding: 20px;
  align-items: flex-start;
  padding-top: 20px;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
}
.mail-cloud-container57 {
  fill: var(--dl-color-primary-lila);
  flex: 1;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  justify-content: center;
  background-image: linear-gradient(0deg, rgb(251, 252, 255) 0.00%,rgb(231, 241, 255) 99.00%);
}
.mail-cloud-icon16 {
  width: 65px;
  height: 65px;
}
.mail-cloud-container58 {
  gap: 10px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.mail-cloud-text50 {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}
.mail-cloud-container59 {
  gap: 20px;
  flex: 0 0 auto;
  width: 346px;
  height: 100%;
  display: flex;
  padding: 20px;
  align-items: flex-start;
  padding-top: 20px;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
}
.mail-cloud-container60 {
  fill: var(--dl-color-primary-lila);
  flex: 1;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  justify-content: center;
  background-image: linear-gradient(0deg, rgb(251, 252, 255) 0.00%,rgb(231, 241, 255) 99.00%);
}
.mail-cloud-icon18 {
  width: 65px;
  height: 65px;
}
.mail-cloud-container61 {
  gap: 10px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.mail-cloud-text52 {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}
.mail-cloud-container62 {
  gap: 20px;
  flex: 0 0 auto;
  width: 346px;
  height: 100%;
  display: flex;
  padding: 20px;
  align-items: flex-start;
  padding-top: 20px;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
}
.mail-cloud-container63 {
  fill: var(--dl-color-primary-lila);
  flex: 1;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  justify-content: center;
  background-image: linear-gradient(0deg, rgb(251, 252, 255) 0.00%,rgb(231, 241, 255) 99.00%);
}
.mail-cloud-icon20 {
  width: 65px;
  height: 65px;
}
.mail-cloud-container64 {
  gap: 10px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.mail-cloud-text54 {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}
.mail-cloud-container65 {
  gap: 20px;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  padding-top: 40px;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  padding-left: 120px;
  padding-right: 120px;
  flex-direction: column;
  padding-bottom: 40px;
  background-image: linear-gradient(0deg, rgb(251, 252, 255) 0.00%,rgb(231, 241, 255) 99.00%);
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.mail-cloud-container66 {
  gap: 10px;
  width: 624px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.mail-cloud-text56 {
  color: var(--dl-color-primary-lila);
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
}
.mail-cloud-text57 {
  text-align: center;
}
.mail-cloud-container67 {
  flex: 0 0 auto;
  width: 400px;
  height: 50px;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  background-color: var(--dl-color-gray-white);
}
.mail-cloud-textinput {
  flex: 1;
  height: 100%;
  padding-left: 20px;
}
.mail-cloud-container68 {
  flex: 0 0 auto;
  width: 50px;
  cursor: pointer;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.mail-cloud-icon22 {
  width: 18px;
  height: 18px;
}
