.success-stories-component-container {
  gap: 30px;
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: center;
  padding-top: 40px;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  padding-left: 20px;
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: 20px;
  flex-direction: column;
  padding-bottom: 20px;
  background-size: cover;
  background-image: url("/world%20map.svg");
}
.success-stories-component-container1 {
  gap: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.success-stories-component-text {
  color: var(--dl-color-primary-lila);
  font-size: 30px;
  font-style: normal;
  text-align: center;
  font-weight: 700;
}
.success-stories-component-container2 {
  gap: 40px;
  flex: 0 0 auto;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.success-stories-component-container3 {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.success-stories-component-container4 {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.success-stories-component-container5 {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.success-stories-component-container6 {
  display: flex;
  align-items: center;
  flex-direction: column;
}


