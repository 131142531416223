.about-us-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.about-us-container01 {
  top: 0px;
  left: 0px;
  width: 100%;
  display: flex;
  z-index: 100;
  position: absolute;
  align-items: center;
  flex-direction: column;
}
.about-us-container02 {
  width: 100%;
  height: 100vh;
  display: flex;
  overflow: scroll;
  align-items: center;
  flex-direction: column;
}
.about-us-container03 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.about-us-container04 {
  width: 100%;
  height: 500px;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  background-image: url("/curve%20line.svg");
}
.about-us-container05 {
  gap: 40px;
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 60px;
  padding-right: 60px;
  flex-direction: row;
  justify-content: center;
}
.about-us-container06 {
  gap: 35px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.about-us-container07 {
  gap: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.about-us-container08 {
  flex: 0 0 auto;
  width: 200px;
  cursor: pointer;
  height: 40px;
  display: flex;
  transition: 0.1s;
  align-items: center;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  padding-left: 20px;
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: 20px;
  justify-content: center;
  background-image: linear-gradient(35deg, rgb(104, 64, 165) 1.00%,rgb(148, 84, 224) 39.00%,rgb(204, 56, 207) 100.00%);
}
.about-us-container08:hover {
  width: 220px;
}
.about-us-container09 {
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.about-us-container10 {
  width: 100%;
  height: 400px;
  display: none;
  opacity: 0.7;
  align-items: center;
  border-color: rgba(153, 153, 153, 0.2);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: row;
  justify-content: center;
  background-color: #fdfdfd;
}
.about-us-image {
  width: 200px;
  object-fit: cover;
}
.about-us-container11 {
  width: 100%;
  height: 20px;
  display: flex;
  opacity: 0.3;
  box-shadow: 5px -5px 10px 0px #bfd5d8;
  align-items: flex-start;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  background-color: var(--dl-color-primary-hell);
  border-left-width: 0px;
  border-right-width: 0px;
}
.about-us-container12 {
  width: 100%;
  height: 450px;
  display: flex;
  align-items: center;
  padding-top: 40px;
  padding-left: 120px;
  padding-right: 120px;
  padding-bottom: 40px;
}
.about-us-container13 {
  gap: 20px;
  flex: 1;
  width: 200px;
  height: 100%;
  display: flex;
  align-items: flex-start;
  padding-right: 40px;
  flex-direction: column;
}
.about-us-container14 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.about-us-text03 {
  color: var(--dl-color-primary-lila);
  font-style: normal;
  font-weight: 500;
}
.about-us-text05 {
  opacity: 0.7;
}
.about-us-container15 {
  flex: 1;
  width: 200px;
  height: 100%;
  display: flex;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  justify-content: center;
}
.about-us-image1 {
  width: 260px;
  height: 220px;
  object-fit: cover;
}
.about-us-container16 {
  width: 100%;
  height: 450px;
  display: flex;
  align-items: center;
  padding-top: 40px;
  padding-left: 120px;
  padding-right: 120px;
  padding-bottom: 40px;
}
.about-us-container17 {
  flex: 1;
  width: 200px;
  height: 100%;
  display: flex;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  justify-content: center;
}
.about-us-image2 {
  width: auto;
  height: 100%;
  object-fit: cover;
}
.about-us-container18 {
  gap: 20px;
  flex: 1;
  width: 200px;
  height: 100%;
  display: flex;
  align-items: flex-start;
  padding-left: 40px;
  flex-direction: column;
}
.about-us-container19 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.about-us-text06 {
  color: var(--dl-color-primary-lila);
  font-style: normal;
  font-weight: 500;
}
.about-us-text08 {
  opacity: 0.7;
}
.about-us-container20 {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 40px;
  padding-left: 120px;
  padding-right: 120px;
  padding-bottom: 40px;
  justify-content: center;
}
.about-us-container21 {
  width: 100%;
  height: 450px;
  display: flex;
  position: relative;
  align-items: center;
  padding-top: 40px;
  padding-left: 120px;
  padding-right: 120px;
  padding-bottom: 40px;
}
.about-us-container22 {
  gap: 20px;
  flex: 1;
  width: 200px;
  height: 100%;
  display: flex;
  align-items: flex-start;
  padding-right: 40px;
  flex-direction: column;
}
.about-us-container23 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.about-us-text09 {
  color: var(--dl-color-primary-lila);
  font-style: normal;
  font-weight: 500;
}
.about-us-text10 {
  text-align: left;
}
.about-us-text14 {
  opacity: 0.7;
}
.about-us-container24 {
  flex: 1;
  width: 200px;
  height: 100%;
  display: flex;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  justify-content: center;
}
.about-us-image3 {
  width: auto;
  height: 100%;
  object-fit: cover;
}
