.price-item-price-item {
  flex: 0 0 auto;
  width: 100%;
  height: 60px;
  display: flex;
  position: relative;
  align-items: center;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  padding-left: 20px;
  padding-right: 20px;
  justify-content: space-between;
  background-color: rgba(231, 241, 255, 0.5);
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.price-item-icon {
  fill: var(--dl-color-gray-black);
  width: 20px;
  height: 20px;
  opacity: 0.3;
}
.price-item-container {
  top: 0px;
  flex: 0 0 auto;
  left: 250px;
  color: var(--dl-color-gray-white);
  height: auto;
  margin: auto;
  display: flex;
  padding: 20px;
  z-index: 100;
  position: absolute;
  max-width: 330px;
  min-width: 230px;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius8);
  background-color: var(--dl-color-gray-black);
}
.price-item-text1 {
  max-width: 250px;
}
