.top-bar-container {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
}
.top-bar-container01 {
  width: 100%;
  height: 60px;
  display: flex;
  z-index: 100;
  align-items: center;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  padding-left: 60px;
  padding-right: 60px;
  justify-content: space-between;
  background-color: var(--dl-color-gray-white);
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.top-bar-image {
  width: auto;
  height: 30px;
  z-index: 100;
  object-fit: cover;
}
.top-bar-container02 {
  gap: 35px;
  top: 0px;
  left: 0px;
  right: 0px;
  height: 60px;
  margin: auto;
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
}
.top-bar-navlink {
  display: contents;
}
.top-bar-container03 {
  gap: 5px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
}
.top-bar-text {
  cursor: pointer;
  opacity: 0.5;
  transition: 0.1;
}
.top-bar-text:hover {
  opacity: 1;
}
.top-bar-container04 {
  width: 100%;
  height: 2px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.top-bar-container05 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius4);
  background-color: var(--dl-color-primary-lila);
}
.top-bar-navlink1 {
  display: contents;
}
.top-bar-container06 {
  cursor: pointer;
  display: none;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
}
.top-bar-text02 {
  opacity: 0.5;
  transition: 0.1s;
}
.top-bar-text02:hover {
  opacity: 1;
}
.top-bar-text03 {
  cursor: pointer;
  font-style: normal;
  font-weight: 600;
}
.top-bar-container07 {
  width: 100%;
  height: 2px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.top-bar-container08 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius4);
  background-color: var(--dl-color-primary-lila);
}
.top-bar-navlink2 {
  display: contents;
}
.top-bar-container09 {
  gap: 5px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
}
.top-bar-text05 {
  cursor: pointer;
  font-style: normal;
  font-weight: 600;
}
.top-bar-container10 {
  width: 100%;
  height: 2px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.top-bar-container11 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius4);
  background-color: var(--dl-color-primary-lila);
}
.top-bar-navlink3 {
  display: contents;
}
.top-bar-container12 {
  gap: 5px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
}
.top-bar-text06 {
  cursor: pointer;
  opacity: 0.5;
  transition: 0.1s;
}
.top-bar-text06:hover {
  opacity: 1;
}
.top-bar-text07 {
  cursor: pointer;
  font-style: normal;
  font-weight: 600;
}
.top-bar-container13 {
  width: 100%;
  height: 2px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.top-bar-container14 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius4);
  background-color: var(--dl-color-primary-lila);
}
.top-bar-navlink4 {
  display: contents;
}
.top-bar-container15 {
  gap: 5px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
}
.top-bar-text08 {
  cursor: pointer;
  opacity: 0.5;
  transition: 0.1s;
}
.top-bar-text08:hover {
  opacity: 1;
}
.top-bar-text09 {
  cursor: pointer;
  font-style: normal;
  font-weight: 600;
}
.top-bar-container16 {
  width: 100%;
  height: 2px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.top-bar-container17 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius4);
  background-color: var(--dl-color-primary-lila);
}
.top-bar-navlink5 {
  display: contents;
}
.top-bar-container18 {
  gap: 5px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
}
.top-bar-text10 {
  cursor: pointer;
  opacity: 0.5;
  transition: 0.1s;
}
.top-bar-text10:hover {
  opacity: 1;
}
.top-bar-text11 {
  cursor: pointer;
  font-style: normal;
  font-weight: 600;
}
.top-bar-container19 {
  width: 100%;
  height: 2px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.top-bar-container20 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius4);
  background-color: var(--dl-color-primary-lila);
}
.top-bar-container21 {
  gap: 10px;
  height: auto;
  display: flex;
  z-index: 100;
  align-items: center;
  justify-content: flex-end;
}
.top-bar-container22 {
  flex: 0 0 auto;
  color: var(--dl-color-gray-700);
  width: auto;
  cursor: pointer;
  height: 40px;
  display: flex;
  transition: 0.1;
  align-items: center;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  padding-left: 20px;
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: 20px;
  justify-content: center;
  background-color: var(--dl-color-gray-white);
}
.top-bar-container22:hover {
  color: var(--dl-color-gray-black);
}
.top-bar-link {
  display: contents;
}
.top-bar-container23 {
  flex: 0 0 auto;
  width: auto;
  cursor: pointer;
  height: 40px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  padding-left: 20px;
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: 20px;
  justify-content: center;
  text-decoration: none;
}
.top-bar-container24 {
  top: 0px;
  left: 1px;
  right: 0px;
  width: 100%;
  bottom: 1px;
  height: 100vh;
  display: flex;
  position: absolute;
  align-items: center;
  flex-direction: column;
  backdrop-filter: blur(10px);
  justify-content: center;
  background-color: rgba(81, 103, 175, 0.1);
}
.top-bar-container25 {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
}
.top-bar-container26 {
  left: 0px;
  right: 0px;
  width: auto;
  bottom: 0px;
  height: 691px;
  display: none;
  z-index: 100;
  position: absolute;
}
.top-bar-container27 {
  display: contents;
}
