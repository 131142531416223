.privacy-policy-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.privacy-policy-container01 {
  width: 100%;
  display: flex;
  z-index: 100;
  align-items: center;
  flex-direction: column;
}
.privacy-policy-container02 {
  width: 100%;
  height: 100vh;
  display: flex;
  overflow: scroll;
  align-items: center;
  flex-direction: column;
}
.privacy-policy-container03 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.privacy-policy-container04 {
  width: 100%;
  height: 500px;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  background-image: url("/curve%20line.svg");
}
.privacy-policy-container05 {
  gap: 40px;
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 60px;
  padding-right: 60px;
  flex-direction: row;
  justify-content: center;
}
.privacy-policy-container06 {
  gap: 35px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.privacy-policy-container07 {
  gap: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.privacy-policy-container08 {
  flex: 0 0 auto;
  width: 200px;
  cursor: pointer;
  height: 40px;
  display: flex;
  transition: 0.1s;
  align-items: center;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  padding-left: 20px;
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: 20px;
  justify-content: center;
  background-image: linear-gradient(35deg, rgb(104, 64, 165) 1.00%,rgb(148, 84, 224) 39.00%,rgb(204, 56, 207) 100.00%);
}
.privacy-policy-container08:hover {
  width: 220px;
}
.privacy-policy-container09 {
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.privacy-policy-container10 {
  width: 100%;
  height: 400px;
  display: none;
  opacity: 0.7;
  align-items: center;
  border-color: rgba(153, 153, 153, 0.2);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: row;
  justify-content: center;
  background-color: #fdfdfd;
}
.privacy-policy-image {
  width: 200px;
  object-fit: cover;
}
.privacy-policy-container11 {
  width: 100%;
  height: 20px;
  display: flex;
  opacity: 0.3;
  box-shadow: 5px -5px 10px 0px #bfd5d8;
  align-items: flex-start;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  background-color: var(--dl-color-primary-hell);
  border-left-width: 0px;
  border-right-width: 0px;
}
.privacy-policy-container12 {
  gap: 30px;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  padding-top: 40px;
  padding-left: 120px;
  padding-right: 120px;
  flex-direction: column;
  padding-bottom: 40px;
}
.privacy-policy-text006 {
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
}
.privacy-policy-text007 {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}
.privacy-policy-container13 {
  gap: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.privacy-policy-text008 {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}
.privacy-policy-text009 {
  text-align: center;
}
.privacy-policy-container14 {
  gap: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.privacy-policy-text017 {
  font-size: 18px;
  font-style: normal;
  text-align: center;
  font-weight: 600;
}
.privacy-policy-text021 {
  text-align: center;
}
.privacy-policy-container15 {
  gap: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.privacy-policy-text025 {
  font-size: 18px;
  font-style: normal;
  text-align: center;
  font-weight: 600;
}
.privacy-policy-text026 {
  text-align: center;
}
.privacy-policy-container16 {
  gap: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.privacy-policy-text031 {
  font-size: 18px;
  font-style: normal;
  text-align: center;
  font-weight: 600;
}
.privacy-policy-text032 {
  text-align: center;
}
.privacy-policy-container17 {
  gap: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.privacy-policy-text036 {
  font-size: 18px;
  font-style: normal;
  text-align: center;
  font-weight: 600;
}
.privacy-policy-text037 {
  text-align: center;
}
.privacy-policy-text051 {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}
.privacy-policy-container18 {
  gap: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.privacy-policy-text052 {
  text-align: center;
}
.privacy-policy-container19 {
  gap: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.privacy-policy-text053 {
  font-size: 18px;
  font-style: normal;
  text-align: center;
  font-weight: 600;
}
.privacy-policy-text054 {
  text-align: center;
}
.privacy-policy-text062 {
  text-align: center;
}
.privacy-policy-container20 {
  gap: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.privacy-policy-text074 {
  font-size: 18px;
  font-style: normal;
  text-align: center;
  font-weight: 600;
}
.privacy-policy-text075 {
  text-align: center;
}
.privacy-policy-text083 {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}
.privacy-policy-container21 {
  gap: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.privacy-policy-text084 {
  font-size: 18px;
  font-style: normal;
  text-align: center;
  font-weight: 600;
}
.privacy-policy-text085 {
  text-align: center;
}
.privacy-policy-text091 {
  text-align: center;
}
.privacy-policy-text099 {
  text-align: center;
}
.privacy-policy-container22 {
  gap: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.privacy-policy-text105 {
  font-size: 18px;
  font-style: normal;
  text-align: center;
  font-weight: 600;
}
.privacy-policy-text106 {
  text-align: center;
}
.privacy-policy-text107 {
  text-align: center;
}
.privacy-policy-text115 {
  text-align: center;
}
.privacy-policy-text116 {
  text-align: center;
}
.privacy-policy-container23 {
  gap: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.privacy-policy-text122 {
  font-size: 18px;
  font-style: normal;
  text-align: center;
  font-weight: 600;
}
.privacy-policy-text123 {
  text-align: center;
}
.privacy-policy-container24 {
  gap: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.privacy-policy-text135 {
  font-size: 18px;
  font-style: normal;
  text-align: center;
  font-weight: 600;
}
.privacy-policy-text139 {
  text-align: center;
}
.privacy-policy-container25 {
  gap: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.privacy-policy-text165 {
  font-size: 18px;
  font-style: normal;
  text-align: center;
  font-weight: 600;
}
.privacy-policy-text168 {
  text-align: center;
}
.privacy-policy-container26 {
  gap: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.privacy-policy-text174 {
  font-size: 18px;
  font-style: normal;
  text-align: center;
  font-weight: 600;
}
.privacy-policy-text179 {
  text-align: center;
}
.privacy-policy-text201 {
  text-align: center;
}
.privacy-policy-container27 {
  gap: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.privacy-policy-text215 {
  font-size: 18px;
  font-style: normal;
  text-align: center;
  font-weight: 600;
}
.privacy-policy-text218 {
  text-align: center;
}
.privacy-policy-container28 {
  gap: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.privacy-policy-text227 {
  font-size: 18px;
  font-style: normal;
  text-align: center;
  font-weight: 600;
}
.privacy-policy-text228 {
  text-align: center;
}
.privacy-policy-container29 {
  gap: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.privacy-policy-text237 {
  font-size: 18px;
  font-style: normal;
  text-align: center;
  font-weight: 600;
}
.privacy-policy-text240 {
  text-align: center;
}
.privacy-policy-container30 {
  gap: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.privacy-policy-text249 {
  font-size: 18px;
  font-style: normal;
  text-align: center;
  font-weight: 600;
}
.privacy-policy-text250 {
  text-align: center;
}
.privacy-policy-text257 {
  text-align: center;
}
.privacy-policy-text263 {
  text-align: center;
}
.privacy-policy-text267 {
  text-align: center;
}
.privacy-policy-text273 {
  text-align: center;
}
.privacy-policy-text281 {
  text-align: center;
}
.privacy-policy-container31 {
  gap: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.privacy-policy-text291 {
  font-size: 18px;
  font-style: normal;
  text-align: center;
  font-weight: 600;
}
.privacy-policy-text292 {
  text-align: center;
}
.privacy-policy-text301 {
  text-align: center;
}
.privacy-policy-text305 {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}
.privacy-policy-container32 {
  gap: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.privacy-policy-text306 {
  font-size: 18px;
  font-style: normal;
  text-align: center;
  font-weight: 600;
}
.privacy-policy-text307 {
  text-align: center;
}
.privacy-policy-text314 {
  text-align: center;
}
.privacy-policy-text326 {
  text-align: center;
}
