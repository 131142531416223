.contact-request-component-container {
  gap: 20px;
  flex: 1;
  width: 200px;
  height: 100%;
  display: flex;
  position: relative;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
}
.contact-request-component-container1 {
  flex: 0 0 auto;
  width: 400px;
  height: 50px;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  background-color: var(--dl-color-gray-white);
}
.contact-request-component-textinput {
  flex: 1;
  height: 100%;
  padding-left: 20px;
}
.contact-request-component-container2 {
  flex: 0 0 auto;
  width: 400px;
  height: 50px;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  background-color: var(--dl-color-gray-white);
}
.contact-request-component-textinput1 {
  flex: 1;
  height: 100%;
  padding-left: 20px;
}
.contact-request-component-container3 {
  flex: 0 0 auto;
  width: 400px;
  height: 50px;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  background-color: var(--dl-color-gray-white);
}
.contact-request-component-textinput2 {
  flex: 1;
  height: 100%;
  padding-left: 20px;
}
.contact-request-component-container4 {
  flex: 0 0 auto;
  width: 400px;
  height: 200px;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  background-color: var(--dl-color-gray-white);
}
.contact-request-component-textarea {
  width: 100%;
  height: 100%;
  padding: 20px;
}
.contact-request-component-container5 {
  flex: 0 0 auto;
  width: 400px;
  height: 40px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  padding-left: 20px;
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: 20px;
  justify-content: center;
  background-image: linear-gradient(35deg, rgb(104, 64, 165) 1.00%,rgb(148, 84, 224) 39.00%,rgb(204, 56, 207) 100.00%);
}
.contact-request-component-container6 {
  flex: 0 0 auto;
  width: 400px;
  height: 40px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  justify-content: center;
}
.contact-request-component-root-class-name {
  display: none;
}
