.calendly-calendly {
  width: auto;
  height: auto;
  display: flex;
  overflow: hidden;
  position: relative;
  margin-top: 60px;
  align-items: flex-end;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  background-color: var(--dl-color-gray-white);
}
.calendly-container {
  width: 575px;
  height: 635px;
  margin: 5px;
  z-index: 100;
}
.calendly-container1 {
  display: contents;
}
.calendly-container2 {
  top: 0px;
  flex: 0 0 auto;
  left: 0px;
  right: 0px;
  width: 575px;
  bottom: 0px;
  height: 635px;
  display: flex;
  position: absolute;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.calendly-text {
  opacity: 0.2;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
}

