.home-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.home-container01 {
  top: 0px;
  left: 0px;
  width: 100%;
  display: flex;
  z-index: 100;
  position: absolute;
  align-items: center;
  flex-direction: column;
}
.home-container02 {
  width: 100%;
  height: 100vh;
  display: flex;
  overflow: scroll;
  align-items: center;
  flex-direction: column;
}
.home-container03 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-container04 {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-size: contain;
  background-image: url("/curve%20line.svg");
  background-position: center;
}
.home-container05 {
  gap: 20px;
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-container06 {
  flex: 0 0 auto;
  width: 100%;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  height: 60px;
  display: flex;
  align-items: flex-start;
}
.home-container07 {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 60px;
  flex-direction: row;
  justify-content: center;
}
.home-container08 {
  gap: 35px;
  flex: 0.8;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.home-text01 {
  text-align: left;
}
.home-container09 {
  flex: 0 0 auto;
  width: 200px;
  cursor: pointer;
  height: 40px;
  display: flex;
  transition: 0.1s;
  align-items: center;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  padding-left: 20px;
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: 20px;
  justify-content: center;
  background-image: linear-gradient(35deg, rgb(104, 64, 165) 1.00%,rgb(148, 84, 224) 39.00%,rgb(204, 56, 207) 100.00%);
}
.home-container09:hover {
  width: 220px;
}
.home-container10 {
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-container11 {
  width: 100%;
  height: 400px;
  display: none;
  opacity: 0.7;
  align-items: center;
  border-color: rgba(153, 153, 153, 0.2);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: row;
  justify-content: center;
  background-color: #fdfdfd;
}
.home-image {
  width: 200px;
  object-fit: cover;
}
.home-image1 {
  width: 100%;
  height: auto;
  object-fit: cover;
}
.home-container12 {
  gap: 10px;
  width: 100%;
  height: 105px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  padding-left: 60px;
  padding-right: 60px;
  flex-direction: column;
  padding-bottom: 15px;
  justify-content: flex-end;
  background-color: var(--dl-color-gray-white);
  border-top-width: 1px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.home-container13 {
  gap: 30px;
  display: flex;
  font-size: 22px;
  align-items: center;
  font-weight: 600;
  flex-direction: row;
}
.home-container14 {
  gap: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 60px;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  padding-left: 120px;
  padding-right: 120px;
  flex-direction: column;
  padding-bottom: 60px;
  justify-content: center;
  background-image: linear-gradient(0deg, rgb(251, 252, 255) 0.00%,rgb(231, 241, 255) 99.00%);
  border-left-width: 0px;
  border-right-width: 0px;
}
.home-container15 {
  gap: 30px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-text12 {
  font-size: 30px;
  font-style: normal;
  text-align: center;
  font-weight: 600;
}
.home-text13 {
  opacity: 0.7;
  text-align: center;
}
.home-container16 {
  gap: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-link {
  display: contents;
}
.home-container17 {
  gap: 20px;
  flex: 0 0 auto;
  width: 190px;
  cursor: pointer;
  filter: grayscale(100%);
  display: flex;
  transition: 0.1s;
  align-items: center;
  padding-top: 20px;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  padding-left: 20px;
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: 20px;
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
  background-color: var(--dl-color-gray-white);
}
.home-container17:hover {
  color: var(--dl-color-primary-lila);
  filter: grayscale(0%);
  box-shadow: 3px 3px 3px 0px rgba(212, 212, 212, 0.14);
  font-weight: 600;
}
.home-container18 {
  width: 100%;
  height: 140px;
  display: flex;
  overflow: hidden;
  align-items: center;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  justify-content: center;
}
.home-image2 {
  width: 100%;
  height: auto;
  object-fit: cover;
}
.home-container19 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: 10px;
  align-items: flex-start;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  justify-content: center;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.home-link1 {
  display: contents;
}
.home-container20 {
  gap: 20px;
  flex: 0 0 auto;
  width: 190px;
  cursor: pointer;
  filter: grayscale(100%);
  display: flex;
  transition: 0.1s;
  align-items: center;
  padding-top: 20px;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  padding-left: 20px;
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: 20px;
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
  background-color: var(--dl-color-gray-white);
}
.home-container20:hover {
  color: var(--dl-color-primary-lila);
  filter: grayscale(0%);
  box-shadow: 3px 3px 3px 0px rgba(212, 212, 212, 0.14);
  font-weight: 600;
}
.home-container21 {
  width: 100%;
  height: 140px;
  display: flex;
  overflow: hidden;
  align-items: center;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  justify-content: center;
  background-color: #f7f7f7;
}
.home-image3 {
  width: 100%;
  height: auto;
  object-fit: cover;
}
.home-container22 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: 10px;
  align-items: flex-start;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  justify-content: center;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.home-link2 {
  display: contents;
}
.home-container23 {
  gap: 20px;
  flex: 0 0 auto;
  width: 190px;
  cursor: pointer;
  filter: grayscale();
  display: flex;
  transition: 0.1s;
  align-items: center;
  padding-top: 20px;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  padding-left: 20px;
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: 20px;
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
  background-color: var(--dl-color-gray-white);
}
.home-container23:hover {
  color: var(--dl-color-primary-lila);
  filter: grayscale(0%);
  box-shadow: 3px 3px 3px 0px rgba(212, 212, 212, 0.14);
  font-weight: 600;
}
.home-container24 {
  width: 100%;
  height: 140px;
  display: flex;
  overflow: hidden;
  align-items: center;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  justify-content: center;
  background-color: #f7f7f7;
}
.home-image4 {
  width: 90%;
  height: auto;
  object-fit: cover;
}
.home-container25 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: 10px;
  align-items: flex-start;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  justify-content: center;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.home-link3 {
  display: contents;
}
.home-container26 {
  gap: 20px;
  flex: 0 0 auto;
  width: 190px;
  cursor: pointer;
  filter: grayscale();
  display: flex;
  transition: 0.1s;
  align-items: center;
  padding-top: 20px;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  padding-left: 20px;
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: 20px;
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
  background-color: var(--dl-color-gray-white);
}
.home-container26:hover {
  color: var(--dl-color-primary-lila);
  filter: grayscale(0%);
  box-shadow: 3px 3px 3px 0px rgba(212, 212, 212, 0.14);
  font-weight: 600;
}
.home-container27 {
  width: 100%;
  height: 140px;
  display: flex;
  overflow: hidden;
  align-items: center;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  justify-content: center;
  background-color: #f7f7f7;
}
.home-image5 {
  width: 90%;
  height: auto;
  object-fit: cover;
}
.home-container28 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: 10px;
  align-items: flex-start;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  justify-content: center;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.home-container29 {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  padding-top: 60px;
  padding-left: 120px;
  padding-right: 120px;
  padding-bottom: 60px;
}
.home-container30 {
  flex: 1;
  width: 200px;
  height: 100%;
  display: flex;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  justify-content: center;
}
.home-image6 {
  width: 427px;
  height: 388px;
  object-fit: cover;
}
.home-container31 {
  gap: 40px;
  flex: 1;
  width: 200px;
  height: 100%;
  display: flex;
  align-items: flex-start;
  padding-left: 40px;
  flex-direction: column;
}
.home-container32 {
  gap: 20px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-container33 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text23 {
  opacity: 0.7;
  text-align: left;
}
.home-container34 {
  gap: 20px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-container35 {
  gap: 20px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
}
.home-container36 {
  fill: var(--dl-color-gray-white);
  flex: 0 0 auto;
  width: 20px;
  height: 20px;
  display: flex;
  padding: 4px;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  background-color: var(--dl-color-primary-lila);
}
.home-icon {
  width: 100%;
  height: 100%;
}
.home-container37 {
  gap: 5px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-container38 {
  gap: 20px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
}
.home-container39 {
  fill: var(--dl-color-gray-white);
  flex: 0 0 auto;
  width: 20px;
  height: 20px;
  display: flex;
  padding: 4px;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  background-color: var(--dl-color-primary-lila);
}
.home-icon02 {
  width: 100%;
  height: 100%;
}
.home-container40 {
  gap: 5px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-container41 {
  gap: 20px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
}
.home-container42 {
  fill: var(--dl-color-gray-white);
  flex: 0 0 auto;
  width: 20px;
  height: 20px;
  display: flex;
  padding: 4px;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  background-color: var(--dl-color-primary-lila);
}
.home-icon04 {
  width: 100%;
  height: 100%;
}
.home-container43 {
  gap: 5px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-container44 {
  gap: 20px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
}
.home-container45 {
  fill: var(--dl-color-gray-white);
  flex: 0 0 auto;
  width: 20px;
  height: 20px;
  display: flex;
  padding: 4px;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  background-color: var(--dl-color-primary-lila);
}
.home-icon06 {
  width: 100%;
  height: 100%;
}
.home-container46 {
  gap: 5px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-container47 {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 40px;
  padding-left: 120px;
  padding-right: 120px;
  padding-bottom: 40px;
  justify-content: center;
}
.home-container48 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: 60px;
  padding-left: 120px;
  padding-right: 120px;
  padding-bottom: 60px;
}
.home-container49 {
  gap: 40px;
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-container50 {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.home-container51 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text31 {
  color: var(--dl-color-primary-lila);
}
.home-text32 {
  text-align: left;
}
.home-navlink {
  display: contents;
}
.home-container52 {
  flex: 0 0 auto;
  width: auto;
  height: 40px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  padding-left: 20px;
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: 20px;
  justify-content: center;
  text-decoration: none;
  background-image: linear-gradient(35deg, rgb(104, 64, 165) 1.00%,rgb(148, 84, 224) 39.00%,rgb(204, 56, 207) 100.00%);
}
.home-container53 {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
}
.home-container54 {
  flex: 1;
  width: 200px;
  height: 100%;
  display: flex;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  justify-content: center;
}
.home-image7 {
  width: 355px;
  height: 317px;
  object-fit: cover;
}
.home-container55 {
  gap: 40px;
  flex: 1;
  width: 200px;
  height: 100%;
  display: flex;
  align-items: flex-start;
  padding-left: 40px;
  flex-direction: column;
}
.home-text37 {
  text-align: right;
}
.home-container56 {
  width: 100%;
  height: 450px;
  display: none;
  align-items: center;
  padding-top: 40px;
  padding-left: 120px;
  padding-right: 120px;
  padding-bottom: 40px;
}
.home-container57 {
  gap: 20px;
  flex: 1;
  width: 200px;
  height: 100%;
  display: flex;
  align-items: flex-start;
  padding-right: 40px;
  flex-direction: column;
}
.home-container58 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text38 {
  color: var(--dl-color-primary-lila);
  font-style: normal;
  font-weight: 500;
}
.home-text39 {
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
}
.home-text40 {
  opacity: 0.7;
}
.home-container59 {
  gap: 20px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
}
.home-container60 {
  fill: var(--dl-color-gray-white);
  flex: 0 0 auto;
  width: 20px;
  height: 20px;
  display: flex;
  padding: 4px;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  background-color: var(--dl-color-primary-lila);
}
.home-icon08 {
  width: 100%;
  height: 100%;
}
.home-container61 {
  gap: 5px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text44 {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}
.home-container62 {
  gap: 20px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
}
.home-container63 {
  fill: var(--dl-color-gray-white);
  flex: 0 0 auto;
  width: 20px;
  height: 20px;
  display: flex;
  padding: 4px;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  background-color: var(--dl-color-primary-lila);
}
.home-icon10 {
  width: 100%;
  height: 100%;
}
.home-container64 {
  gap: 5px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text49 {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}
.home-container65 {
  flex: 1;
  width: 200px;
  height: 100%;
  display: flex;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  justify-content: center;
}
.home-image8 {
  width: auto;
  height: 100%;
  object-fit: cover;
}
.home-container66 {
  flex: 0 0 auto;
  left: 0px;
  width: 100%;
  bottom: 0px;
  height: 100%;
  display: none;
  position: absolute;
  align-items: flex-start;
  backdrop-filter: blur(10px);
  background-color: rgba(81, 103, 175, 0.1);
}
