.product-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.product-container01 {
  top: 0px;
  left: 0px;
  width: 100%;
  display: flex;
  z-index: 100;
  position: absolute;
  align-items: center;
  flex-direction: column;
}
.product-container02 {
  width: 100%;
  height: 100vh;
  display: flex;
  overflow: scroll;
  align-items: center;
  flex-direction: column;
}
.product-container03 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.product-container04 {
  width: 100%;
  height: 500px;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  background-image: url("/curve%20line.svg");
}
.product-container05 {
  flex: 0 0 auto;
  width: 100%;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  height: 60px;
  display: flex;
  align-items: flex-start;
}
.product-container06 {
  gap: 40px;
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 60px;
  padding-left: 60px;
  padding-right: 60px;
  flex-direction: row;
  padding-bottom: 60px;
  justify-content: center;
}
.product-container07 {
  gap: 35px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.product-container08 {
  gap: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.product-container09 {
  flex: 0 0 auto;
  width: 200px;
  cursor: pointer;
  height: 40px;
  display: flex;
  transition: 0.1s;
  align-items: center;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  padding-left: 20px;
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: 20px;
  justify-content: center;
  background-image: linear-gradient(35deg, rgb(104, 64, 165) 1.00%,rgb(148, 84, 224) 39.00%,rgb(204, 56, 207) 100.00%);
}
.product-container09:hover {
  width: 220px;
}
.product-container10 {
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.product-container11 {
  width: 100%;
  height: 400px;
  display: none;
  opacity: 0.7;
  align-items: center;
  border-color: rgba(153, 153, 153, 0.2);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: row;
  justify-content: center;
  background-color: #fdfdfd;
}
.product-image {
  width: 200px;
  object-fit: cover;
}
.product-container12 {
  width: 100%;
  height: 20px;
  display: flex;
  opacity: 0.3;
  box-shadow: 5px -5px 10px 0px #bfd5d8;
  align-items: flex-start;
  border-color: rgba(120, 120, 120, 0.4);
  border-style: solid;
  border-width: 1px;
  background-color: var(--dl-color-primary-hell);
  border-left-width: 0px;
  border-right-width: 0px;
}
.product-container13 {
  gap: 30px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 40px;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  flex-direction: column;
  padding-bottom: 80px;
  justify-content: center;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.product-container14 {
  gap: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.product-text03 {
  text-align: center;
}
.product-container15 {
  gap: 40px;
  width: 100%;
  height: 550px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.product-container16 {
  gap: 20px;
  flex: 0 0 auto;
  width: 450px;
  height: 550px;
  display: flex;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
}
.product-container17 {
  width: 100%;
  height: 330px;
  display: flex;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius8);
  justify-content: flex-start;
}
.product-image1 {
  width: 300px;
  height: 252px;
  object-fit: cover;
}
.product-container18 {
  gap: 20px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.product-container19 {
  gap: 20px;
  flex: 0 0 auto;
  width: 450px;
  height: 540px;
  display: flex;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
}
.product-container20 {
  width: 100%;
  height: 330px;
  display: flex;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius8);
  justify-content: flex-end;
}
.product-image2 {
  width: 100%;
  height: auto;
  object-fit: cover;
}
.product-container21 {
  gap: 20px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.product-container22 {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: center;
  padding-top: 60px;
  padding-left: 120px;
  padding-right: 120px;
  padding-bottom: 40px;
}
.product-container23 {
  gap: 40px;
  flex: 1;
  width: 200px;
  height: 100%;
  display: flex;
  align-items: flex-start;
  padding-right: 40px;
  flex-direction: column;
}
.product-container24 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.product-text16 {
  color: var(--dl-color-primary-lila);
}
.product-text17 {
  text-align: left;
}
.product-container25 {
  gap: 20px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.product-container26 {
  gap: 20px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
}
.product-container27 {
  fill: var(--dl-color-gray-white);
  flex: 0 0 auto;
  width: 20px;
  height: 20px;
  display: flex;
  padding: 4px;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  background-color: var(--dl-color-primary-lila);
}
.product-icon {
  width: 100%;
  height: 100%;
}
.product-container28 {
  gap: 5px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.product-container29 {
  gap: 20px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
}
.product-container30 {
  fill: var(--dl-color-gray-white);
  flex: 0 0 auto;
  width: 20px;
  height: 20px;
  display: flex;
  padding: 4px;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  background-color: var(--dl-color-primary-lila);
}
.product-icon02 {
  width: 100%;
  height: 100%;
}
.product-container31 {
  gap: 5px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.product-container32 {
  gap: 20px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
}
.product-container33 {
  fill: var(--dl-color-gray-white);
  flex: 0 0 auto;
  width: 20px;
  height: 20px;
  display: flex;
  padding: 4px;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  background-color: var(--dl-color-primary-lila);
}
.product-icon04 {
  width: 100%;
  height: 100%;
}
.product-container34 {
  gap: 5px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.product-container35 {
  gap: 20px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
}
.product-container36 {
  fill: var(--dl-color-gray-white);
  flex: 0 0 auto;
  width: 20px;
  height: 20px;
  display: flex;
  padding: 4px;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  background-color: var(--dl-color-primary-lila);
}
.product-icon06 {
  width: 100%;
  height: 100%;
}
.product-container37 {
  gap: 5px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.product-container38 {
  flex: 1;
  width: 570px;
  height: 414px;
  display: flex;
  padding: 20px;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  justify-content: center;
}
.product-image3 {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.product-container39 {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 40px;
  padding-left: 120px;
  padding-right: 120px;
  padding-bottom: 40px;
  justify-content: center;
}
.product-container40 {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: center;
  padding-top: 40px;
  padding-left: 120px;
  padding-right: 120px;
  padding-bottom: 40px;
}
.product-container41 {
  flex: 1;
  width: 665px;
  height: 429px;
  display: flex;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  justify-content: center;
}
.product-image4 {
  width: 408px;
  height: 389px;
  object-fit: cover;
}
.product-container42 {
  gap: 40px;
  flex: 1;
  width: 200px;
  height: 100%;
  display: flex;
  align-items: flex-start;
  padding-left: 40px;
  flex-direction: column;
}
.product-container43 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.product-text22 {
  color: var(--dl-color-primary-lila);
}
.product-text23 {
  text-align: left;
}
.product-container44 {
  gap: 20px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.product-container45 {
  gap: 5px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.product-container46 {
  gap: 20px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
}
.product-container47 {
  fill: var(--dl-color-gray-white);
  flex: 0 0 auto;
  width: 20px;
  height: 20px;
  display: flex;
  padding: 4px;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  background-color: var(--dl-color-primary-lila);
}
.product-icon08 {
  width: 100%;
  height: 100%;
}
.product-container48 {
  gap: 5px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.product-container49 {
  gap: 20px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
}
.product-container50 {
  fill: var(--dl-color-gray-white);
  flex: 0 0 auto;
  width: 20px;
  height: 20px;
  display: flex;
  padding: 4px;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  background-color: Transparent;
}
.product-container51 {
  gap: 5px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.product-container52 {
  gap: 5px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.product-container53 {
  gap: 20px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
}
.product-container54 {
  fill: var(--dl-color-gray-white);
  flex: 0 0 auto;
  width: 20px;
  height: 20px;
  display: flex;
  padding: 4px;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  background-color: var(--dl-color-primary-lila);
}
.product-icon10 {
  width: 100%;
  height: 100%;
}
.product-container55 {
  gap: 5px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.product-container56 {
  gap: 20px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
}
.product-container57 {
  fill: var(--dl-color-gray-white);
  flex: 0 0 auto;
  width: 20px;
  height: 20px;
  display: flex;
  padding: 4px;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  background-color: Transparent;
}
.product-container58 {
  gap: 5px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.product-container59 {
  gap: 5px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.product-container60 {
  gap: 20px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
}
.product-container61 {
  fill: var(--dl-color-gray-white);
  flex: 0 0 auto;
  width: 20px;
  height: 20px;
  display: flex;
  padding: 4px;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  background-color: var(--dl-color-primary-lila);
}
.product-icon12 {
  width: 100%;
  height: 100%;
}
.product-container62 {
  gap: 5px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.product-container63 {
  gap: 20px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
}
.product-container64 {
  fill: var(--dl-color-gray-white);
  flex: 0 0 auto;
  width: 20px;
  height: 20px;
  display: flex;
  padding: 4px;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  background-color: Transparent;
}
.product-container65 {
  gap: 5px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
