.newsletter-component-container {
  gap: 40px;
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: center;
  padding-top: 40px;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  padding-left: 120px;
  padding-right: 120px;
  flex-direction: column;
  padding-bottom: 40px;
  background-image: linear-gradient(0deg, rgb(251, 252, 255) 0.00%,rgb(231, 241, 255) 99.00%);
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.newsletter-component-container1 {
  gap: 10px;
  width: 624px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.newsletter-component-text {
  color: var(--dl-color-primary-lila);
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
}
.newsletter-component-text1 {
  text-align: center;
}
.newsletter-component-container2 {
  flex: 0 0 auto;
  width: 400px;
  height: 50px;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  background-color: var(--dl-color-gray-white);
}
.newsletter-component-textinput {
  flex: 1;
  height: 100%;
  padding-left: 20px;
}
.newsletter-component-container3 {
  flex: 0 0 auto;
  width: 50px;
  cursor: pointer;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.newsletter-component-icon {
  width: 18px;
  height: 18px;
}
.newsletter-component-container4 {
  flex: 0 0 auto;
  width: 400px;
  height: 50px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  justify-content: center;
}










