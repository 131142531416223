.contact-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.contact-container01 {
  top: 0px;
  left: 0px;
  width: 100%;
  display: flex;
  z-index: 100;
  position: absolute;
  align-items: center;
  flex-direction: column;
}
.contact-container02 {
  width: 100%;
  height: 100vh;
  display: flex;
  overflow: scroll;
  align-items: center;
  flex-direction: column;
}
.contact-container03 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.contact-container04 {
  width: 100%;
  height: 500px;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  background-image: url("/curve%20line.svg");
}
.contact-container05 {
  gap: 40px;
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 60px;
  padding-right: 60px;
  flex-direction: row;
  justify-content: center;
}
.contact-container06 {
  gap: 35px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.contact-container07 {
  gap: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.contact-container08 {
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.contact-container09 {
  width: 100%;
  height: 400px;
  display: none;
  opacity: 0.7;
  align-items: center;
  border-color: rgba(153, 153, 153, 0.2);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: row;
  justify-content: center;
  background-color: #fdfdfd;
}
.contact-image {
  width: 200px;
  object-fit: cover;
}
.contact-container10 {
  width: 100%;
  height: 20px;
  display: flex;
  opacity: 0.3;
  box-shadow: 5px -5px 10px 0px #bfd5d8;
  align-items: flex-start;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  background-color: var(--dl-color-primary-hell);
  border-left-width: 0px;
  border-right-width: 0px;
}
.contact-container11 {
  width: 100%;
  height: auto;
  display: flex;
  padding-top: 40px;
  padding-left: 120px;
  padding-right: 120px;
  padding-bottom: 40px;
}
.contact-container12 {
  gap: 20px;
  flex: 1;
  width: 200px;
  height: 100%;
  display: flex;
  align-items: flex-start;
  padding-right: 40px;
  flex-direction: column;
}
.contact-container13 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.contact-text3 {
  opacity: 0.7;
}
.contact-container14 {
  gap: 20px;
  display: flex;
  margin-top: 40px;
  align-items: center;
  flex-direction: row;
}
.contact-container15 {
  fill: var(--dl-color-primary-lila);
  flex: 0 0 auto;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-primary-hell);
}
.contact-icon {
  width: 18px;
  height: 18px;
}
.contact-container16 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
