.component1-container {
  gap: 20px;
  width: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
.component1-container01 {
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.component1-container02 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: 20px;
  transition: 0.1s;
  align-items: flex-start;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
}
.component1-container02:hover {
  background-color: #e7f1ff;
}
.component1-text {
  font-weight: 500;
}
.component1-container03 {
  gap: 20px;
  width: 100%;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  padding-bottom: 10px;
}
.component1-container04 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: 20px;
  align-items: flex-start;
}
.component1-text1 {
  font-weight: 700;
}
.component1-container05 {
  display: flex;
  align-items: flex-start;
  padding-left: 20px;
  padding-right: 20px;
  flex-direction: column;
}
.component1-text2 {
  font-style: normal;
  text-align: left;
  font-weight: 400;
}
.component1-container06 {
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.component1-container07 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: 20px;
  transition: 0.1s;
  align-items: flex-start;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
}
.component1-container07:hover {
  background-color: #e7f1ff;
}
.component1-text3 {
  font-weight: 500;
}
.component1-container08 {
  gap: 20px;
  width: 100%;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  padding-bottom: 10px;
}
.component1-container09 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: 20px;
  align-items: flex-start;
}
.component1-text4 {
  font-weight: 700;
}
.component1-container10 {
  display: flex;
  align-items: flex-start;
  padding-left: 20px;
  padding-right: 20px;
  flex-direction: column;
}
.component1-text5 {
  font-style: normal;
  text-align: left;
  font-weight: 400;
}
.component1-container11 {
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.component1-container12 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: 20px;
  transition: 0.1s;
  align-items: flex-start;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
}
.component1-container12:hover {
  background-color: #e7f1ff;
}
.component1-text6 {
  font-weight: 500;
}
.component1-container13 {
  gap: 20px;
  width: 100%;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  padding-bottom: 10px;
}
.component1-container14 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: 20px;
  align-items: flex-start;
}
.component1-text7 {
  font-weight: 700;
}
.component1-container15 {
  display: flex;
  align-items: flex-start;
  padding-left: 20px;
  padding-right: 20px;
  flex-direction: column;
}
.component1-text8 {
  font-style: normal;
  text-align: left;
  font-weight: 400;
}
