.component2-container {
  gap: 40px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  padding-top: 80px;
  flex-direction: column;
  padding-bottom: 80px;
  justify-content: center;
}
.component2-container01 {
  gap: 30px;
  width: 906px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.component2-text04 {
  font-style: normal;
  text-align: center;
  font-weight: 300;
}
.component2-container02 {
  gap: 40px;
  width: 100%;
  height: 300px;
  display: none;
  align-items: flex-start;
  justify-content: center;
}
.component2-container03 {
  gap: 20px;
  flex: 0 0 auto;
  width: 346px;
  height: 100%;
  display: flex;
  padding: 20px;
  align-items: flex-start;
  padding-top: 20px;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
}
.component2-container04 {
  fill: var(--dl-color-primary-lila);
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius8);
  justify-content: center;
}
.component2-image {
  width: 65px;
  height: 65px;
  object-fit: cover;
}
.component2-container05 {
  gap: 10px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.component2-container06 {
  gap: 20px;
  flex: 0 0 auto;
  width: 346px;
  height: 100%;
  display: flex;
  padding: 20px;
  align-items: flex-start;
  padding-top: 20px;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
}
.component2-container07 {
  fill: var(--dl-color-primary-lila);
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius8);
  justify-content: center;
}
.component2-image01 {
  width: 65px;
  height: 65px;
  object-fit: cover;
}
.component2-container08 {
  gap: 10px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.component2-container09 {
  gap: 20px;
  flex: 0 0 auto;
  width: 346px;
  height: 100%;
  display: flex;
  padding: 20px;
  align-items: flex-start;
  padding-top: 20px;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
}
.component2-container10 {
  fill: var(--dl-color-primary-lila);
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius8);
  justify-content: center;
}
.component2-image02 {
  width: 65px;
  height: 65px;
  object-fit: cover;
}
.component2-container11 {
  gap: 10px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.component2-container12 {
  gap: 40px;
  width: 100%;
  height: 300px;
  display: none;
  align-items: flex-start;
  justify-content: center;
}
.component2-container13 {
  gap: 20px;
  flex: 0 0 auto;
  width: 346px;
  height: 100%;
  display: flex;
  padding: 20px;
  align-items: flex-start;
  padding-top: 20px;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
}
.component2-container14 {
  fill: var(--dl-color-primary-lila);
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius8);
  justify-content: center;
}
.component2-image03 {
  width: 65px;
  height: 65px;
  object-fit: cover;
}
.component2-container15 {
  gap: 10px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.component2-container16 {
  gap: 20px;
  flex: 0 0 auto;
  width: 346px;
  height: 100%;
  display: flex;
  padding: 20px;
  align-items: flex-start;
  padding-top: 20px;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
}
.component2-container17 {
  fill: var(--dl-color-primary-lila);
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius8);
  justify-content: center;
}
.component2-image04 {
  width: 65px;
  height: 65px;
  object-fit: cover;
}
.component2-container18 {
  gap: 10px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.component2-container19 {
  gap: 20px;
  flex: 0 0 auto;
  width: 346px;
  height: 100%;
  display: flex;
  padding: 20px;
  align-items: flex-start;
  padding-top: 20px;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
}
.component2-container20 {
  fill: var(--dl-color-primary-lila);
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius8);
  justify-content: center;
}
.component2-image05 {
  width: 65px;
  height: 65px;
  object-fit: cover;
}
.component2-container21 {
  gap: 10px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.component2-container22 {
  gap: 100px;
  width: 100%;
  display: flex;
  margin-top: 40px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.component2-container23 {
  gap: 20px;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.component2-container24 {
  gap: 20px;
  width: auto;
  display: flex;
  align-items: flex-start;
}
.component2-container24:hover {
  color: var(--dl-color-primary-lila);
}
.component2-container25 {
  display: flex;
  align-items: flex-start;
}
.component2-container26 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  padding: 20px;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius8);
  justify-content: center;
  background-color: var(--dl-color-primary-hell);
}
.component2-image06 {
  width: 30px;
  height: 30px;
  object-fit: cover;
}
.component2-container27 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  padding: 20px;
  box-shadow: 5px 5px 10px 0px #d4d4d4;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius8);
  padding-bottom: 20px;
  justify-content: center;
  background-color: var(--dl-color-primary-lila);
}
.component2-image07 {
  width: 30px;
  filter: brightness(0) invert(1);
  height: 30px;
  object-fit: cover;
}
.component2-container28 {
  gap: 10px;
  flex: 0 0 auto;
  width: 306px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.component2-text37 {
  fill: var(--dl-color-gray-black);
  color: var(--dl-color-gray-black);
}
.component2-container29 {
  gap: 20px;
  width: auto;
  display: flex;
  align-items: flex-start;
}
.component2-container29:hover {
  color: var(--dl-color-primary-lila);
}
.component2-container30 {
  display: flex;
  align-items: flex-start;
}
.component2-container31 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  padding: 20px;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius8);
  justify-content: center;
  background-color: var(--dl-color-primary-hell);
}
.component2-image08 {
  width: 30px;
  height: 30px;
  object-fit: cover;
}
.component2-container32 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  padding: 20px;
  box-shadow: 5px 5px 10px 0px #d4d4d4;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius8);
  padding-bottom: 20px;
  justify-content: center;
  background-color: var(--dl-color-primary-lila);
}
.component2-image09 {
  width: 30px;
  filter: brightness(0) invert(1);
  height: 30px;
  object-fit: cover;
}
.component2-container33 {
  gap: 10px;
  flex: 0 0 auto;
  width: 306px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.component2-text42 {
  fill: var(--dl-color-gray-black);
  color: var(--dl-color-gray-black);
  text-align: left;
}
.component2-container34 {
  gap: 20px;
  width: auto;
  display: flex;
  align-items: flex-start;
}
.component2-container34:hover {
  color: var(--dl-color-primary-lila);
}
.component2-container35 {
  display: flex;
  align-items: flex-start;
}
.component2-container36 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  padding: 20px;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius8);
  justify-content: center;
  background-color: var(--dl-color-primary-hell);
}
.component2-image10 {
  width: 30px;
  height: 30px;
  object-fit: cover;
}
.component2-container37 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  padding: 20px;
  box-shadow: 5px 5px 10px 0px #d4d4d4;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius8);
  padding-bottom: 20px;
  justify-content: center;
  background-color: var(--dl-color-primary-lila);
}
.component2-image11 {
  width: 30px;
  filter: brightness(0) invert(1);
  height: 30px;
  object-fit: cover;
}
.component2-container38 {
  gap: 10px;
  flex: 0 0 auto;
  width: 306px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.component2-text44 {
  fill: var(--dl-color-gray-black);
  color: var(--dl-color-gray-black);
  text-align: left;
}
.component2-container39 {
  gap: 20px;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.component2-container40 {
  gap: 20px;
  width: auto;
  display: flex;
  align-items: flex-start;
}
.component2-container40:hover {
  color: var(--dl-color-primary-lila);
}
.component2-container41 {
  display: flex;
  align-items: flex-start;
}
.component2-container42 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  padding: 20px;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius8);
  justify-content: center;
  background-color: var(--dl-color-primary-hell);
}
.component2-image12 {
  width: 30px;
  height: 30px;
  object-fit: cover;
}
.component2-container43 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  padding: 20px;
  box-shadow: 5px 5px 10px 0px #d4d4d4;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius8);
  padding-bottom: 20px;
  justify-content: center;
  background-color: var(--dl-color-primary-lila);
}
.component2-image13 {
  width: 30px;
  filter: brightness(0) invert(1);
  height: 30px;
  object-fit: cover;
}
.component2-container44 {
  gap: 10px;
  flex: 0 0 auto;
  width: 306px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.component2-text46 {
  fill: var(--dl-color-gray-black);
  color: var(--dl-color-gray-black);
  text-align: left;
}
.component2-container45 {
  gap: 20px;
  width: auto;
  display: flex;
  align-items: flex-start;
}
.component2-container45:hover {
  color: var(--dl-color-primary-lila);
}
.component2-container46 {
  display: flex;
  align-items: flex-start;
}
.component2-container47 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  padding: 20px;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius8);
  justify-content: center;
  background-color: var(--dl-color-primary-hell);
}
.component2-image14 {
  width: 30px;
  height: 30px;
  object-fit: cover;
}
.component2-container48 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  padding: 20px;
  box-shadow: 5px 5px 10px 0px #d4d4d4;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius8);
  padding-bottom: 20px;
  justify-content: center;
  background-color: var(--dl-color-primary-lila);
}
.component2-image15 {
  width: 30px;
  filter: brightness(0) invert(1);
  height: 30px;
  object-fit: cover;
}
.component2-container49 {
  gap: 10px;
  flex: 0 0 auto;
  width: 306px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.component2-text50 {
  fill: var(--dl-color-gray-black);
  color: var(--dl-color-gray-black);
  text-align: left;
}
.component2-container50 {
  gap: 20px;
  width: auto;
  display: flex;
  align-items: flex-start;
}
.component2-container50:hover {
  color: var(--dl-color-primary-lila);
}
.component2-container51 {
  display: flex;
  align-items: flex-start;
}
.component2-container52 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  padding: 20px;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius8);
  justify-content: center;
  background-color: var(--dl-color-primary-hell);
}
.component2-image16 {
  width: 30px;
  height: 30px;
  object-fit: cover;
}
.component2-container53 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  padding: 20px;
  box-shadow: 5px 5px 10px 0px #d4d4d4;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius8);
  padding-bottom: 20px;
  justify-content: center;
  background-color: var(--dl-color-primary-lila);
}
.component2-image17 {
  width: 30px;
  filter: brightness(0) invert(1);
  height: 30px;
  object-fit: cover;
}
.component2-container54 {
  gap: 10px;
  flex: 0 0 auto;
  width: 306px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.component2-text55 {
  fill: var(--dl-color-gray-black);
  color: var(--dl-color-gray-black);
  text-align: left;
}
