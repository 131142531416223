.bottom-bar-container {
  color: var(--dl-color-gray-white);
  width: 100%;
  height: 346px;
  display: flex;
  position: relative;
  align-items: stretch;
  padding-top: 40px;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  padding-left: 120px;
  padding-right: 120px;
  padding-bottom: 40px;
  background-size: cover;
  justify-content: space-between;
  background-image: url("/curve%20line%20(1).svg");
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.bottom-bar-container01 {
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
}
.bottom-bar-container02 {
  gap: 20px;
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.bottom-bar-navlink {
  display: contents;
}
.bottom-bar-container03 {
  cursor: pointer;
  display: flex;
  padding: 10px;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  text-decoration: none;
  background-color: var(--dl-color-gray-white);
}
.bottom-bar-image {
  width: auto;
  height: 20px;
  z-index: 100;
  object-fit: cover;
}
.bottom-bar-container04 {
  gap: 10px;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.bottom-bar-link {
  display: contents;
}
.bottom-bar-container05 {
  fill: var(--dl-color-primary-lila);
  flex: 0 0 auto;
  width: 30px;
  cursor: pointer;
  height: 30px;
  display: flex;
  padding: 5px;
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  justify-content: center;
  text-decoration: none;
  background-color: var(--dl-color-gray-white);
}
.bottom-bar-icon {
  width: 100%;
  height: 100%;
}
.bottom-bar-link1 {
  display: contents;
}
.bottom-bar-container06 {
  fill: var(--dl-color-primary-lila);
  flex: 0 0 auto;
  width: 30px;
  cursor: pointer;
  height: 30px;
  display: flex;
  padding: 5px;
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  justify-content: center;
  text-decoration: none;
  background-color: var(--dl-color-gray-white);
}
.bottom-bar-icon2 {
  width: 100%;
  height: 100%;
}
.bottom-bar-container07 {
  flex: 0 0 auto;
  width: 837px;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-end;
}
.bottom-bar-container08 {
  gap: 30px;
  flex: 0 0 auto;
  color: var(--dl-color-primary-hell);
  width: 200px;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.bottom-bar-navlink1 {
  display: contents;
}
.bottom-bar-container09 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  text-decoration: none;
}
.bottom-bar-text02 {
  cursor: pointer;
  opacity: 0.6;
  transition: 0.1s;
}
.bottom-bar-text02:hover {
  opacity: 1;
}
.bottom-bar-navlink2 {
  display: contents;
}
.bottom-bar-container10 {
  display: none;
  align-items: flex-start;
  flex-direction: column;
  text-decoration: none;
}
.bottom-bar-text03 {
  cursor: pointer;
  opacity: 0.6;
  transition: 0.1s;
}
.bottom-bar-text03:hover {
  opacity: 1;
}
.bottom-bar-navlink3 {
  display: contents;
}
.bottom-bar-container11 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  text-decoration: none;
}
.bottom-bar-text04 {
  cursor: pointer;
  opacity: 0.6;
  transition: 0.1s;
}
.bottom-bar-text04:hover {
  opacity: 1;
}
.bottom-bar-navlink4 {
  display: contents;
}
.bottom-bar-container12 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  text-decoration: none;
}
.bottom-bar-text05 {
  cursor: pointer;
  opacity: 0.6;
  transition: 0.1s;
}
.bottom-bar-text05:hover {
  opacity: 1;
}
.bottom-bar-navlink5 {
  display: contents;
}
.bottom-bar-container13 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  text-decoration: none;
}
.bottom-bar-text06 {
  cursor: pointer;
  opacity: 0.6;
  transition: 0.1s;
}
.bottom-bar-text06:hover {
  opacity: 1;
}
.bottom-bar-container14 {
  gap: 30px;
  flex: 0 0 auto;
  width: 200px;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.bottom-bar-text07 {
  font-style: normal;
  font-weight: 700;
}
.bottom-bar-navlink6 {
  display: contents;
}
.bottom-bar-container15 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  text-decoration: none;
}
.bottom-bar-text08 {
  cursor: pointer;
  opacity: 0.6;
  transition: 0.1s;
}
.bottom-bar-text08:hover {
  opacity: 1;
}
.bottom-bar-navlink7 {
  display: contents;
}
.bottom-bar-container16 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  text-decoration: none;
}
.bottom-bar-text09 {
  cursor: pointer;
  opacity: 0.6;
  transition: 0.1s;
}
.bottom-bar-text09:hover {
  opacity: 1;
}
.bottom-bar-navlink8 {
  display: contents;
}
.bottom-bar-container17 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  text-decoration: none;
}
.bottom-bar-text10 {
  cursor: pointer;
  opacity: 0.6;
  transition: 0.1s;
}
.bottom-bar-text10:hover {
  opacity: 1;
}
.bottom-bar-container18 {
  gap: 30px;
  flex: 0 0 auto;
  width: 200px;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.bottom-bar-text11 {
  font-size: 16px;
}
.bottom-bar-link2 {
  display: contents;
}
.bottom-bar-container19 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  text-decoration: none;
}
