.pricing-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.pricing-container01 {
  top: 0px;
  left: 0px;
  width: 100%;
  display: flex;
  z-index: 100;
  position: absolute;
  align-items: center;
  flex-direction: column;
}
.pricing-container02 {
  width: 100%;
  height: 100vh;
  display: flex;
  overflow: scroll;
  align-items: center;
  flex-direction: column;
}
.pricing-container03 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.pricing-container04 {
  width: 100%;
  height: 500px;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  background-image: url("/curve%20line.svg");
}
.pricing-container05 {
  flex: 0 0 auto;
  width: 100%;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  height: 60px;
  display: flex;
  align-items: flex-start;
}
.pricing-container06 {
  gap: 40px;
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 60px;
  padding-right: 60px;
  flex-direction: row;
  justify-content: center;
}
.pricing-container07 {
  gap: 35px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.pricing-container08 {
  gap: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.pricing-container09 {
  flex: 0 0 auto;
  width: 200px;
  cursor: pointer;
  height: 40px;
  display: flex;
  transition: 0.1s;
  align-items: center;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  padding-left: 20px;
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: 20px;
  justify-content: center;
  background-image: linear-gradient(35deg, rgb(104, 64, 165) 1.00%,rgb(148, 84, 224) 39.00%,rgb(204, 56, 207) 100.00%);
}
.pricing-container09:hover {
  width: 220px;
}
.pricing-container10 {
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.pricing-container11 {
  width: 100%;
  height: 400px;
  display: none;
  opacity: 0.7;
  align-items: center;
  border-color: rgba(153, 153, 153, 0.2);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: row;
  justify-content: center;
  background-color: #fdfdfd;
}
.pricing-image {
  width: 200px;
  object-fit: cover;
}
.pricing-container12 {
  width: 100%;
  height: 20px;
  display: flex;
  opacity: 0.3;
  box-shadow: 5px -5px 10px 0px #bfd5d8;
  align-items: flex-start;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  background-color: var(--dl-color-primary-hell);
  border-left-width: 0px;
  border-right-width: 0px;
}
.pricing-container13 {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 40px;
  padding-left: 120px;
  padding-right: 120px;
  flex-direction: column;
  padding-bottom: 40px;
}
.pricing-container14 {
  width: 100%;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: row;
}
.pricing-container15 {
  width: 250px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.pricing-container16 {
  width: 100%;
  height: 340px;
  display: flex;
  padding: 20px;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-primary-lila);
}
.pricing-text06 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
}
.pricing-container17 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  flex-direction: column;
  border-top-width: 0px;
  border-left-width: 0px;
  border-bottom-width: 0px;
}
.pricing-container18 {
  flex: 0 0 auto;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  padding-left: 20px;
  padding-right: 20px;
  background-color: var(--dl-color-primary-hell);
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.pricing-container19 {
  flex: 0 0 auto;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  padding-left: 20px;
  padding-right: 20px;
  background-color: rgba(231, 241, 255, 0.5);
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.pricing-container20 {
  flex: 0 0 auto;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  padding-left: 20px;
  padding-right: 20px;
  background-color: rgba(231, 241, 255, 0.5);
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.pricing-container21 {
  flex: 0 0 auto;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  background-color: var(--dl-color-primary-hell);
}
.pricing-container22 {
  flex: 0 0 auto;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  padding-left: 20px;
  padding-right: 20px;
  background-color: rgba(231, 241, 255, 0.5);
  border-top-width: 1px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.pricing-container23 {
  flex: 0 0 auto;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  padding-left: 20px;
  padding-right: 20px;
  background-color: rgba(231, 241, 255, 0.5);
  border-top-width: 1px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.pricing-container24 {
  flex: 0 0 auto;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  padding-left: 20px;
  padding-right: 20px;
  background-color: rgba(231, 241, 255, 0.5);
  border-top-width: 1px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.pricing-container25 {
  flex: 1;
  width: 250px;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  flex-direction: column;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 1px;
  border-bottom-width: 0px;
}
.pricing-container26 {
  width: 100%;
  height: 340px;
  display: flex;
  align-items: center;
  padding-top: 40px;
  padding-left: 20px;
  padding-right: 20px;
  flex-direction: column;
  padding-bottom: 40px;
  justify-content: space-between;
}
.pricing-container27 {
  gap: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.pricing-container28 {
  gap: 2px;
  display: flex;
  align-items: flex-end;
}
.pricing-text22 {
  fill: var(--dl-color-gray-700);
  color: var(--dl-color-gray-700);
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 1.8;
}
.pricing-text23 {
  fill: var(--dl-color-gray-700);
  color: var(--dl-color-gray-700);
  line-height: 2.3;
}
.pricing-text24 {
  text-align: center;
}
.pricing-link {
  display: contents;
}
.pricing-container29 {
  flex: 0 0 auto;
  width: auto;
  cursor: poi;
  height: 40px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  padding-left: 20px;
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: 20px;
  justify-content: center;
  text-decoration: none;
}
.pricing-container30 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.pricing-container31 {
  flex: 0 0 auto;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  padding-left: 20px;
  padding-right: 20px;
  background-color: var(--dl-color-primary-hell);
  border-left-width: 0px;
  border-right-width: 0px;
}
.pricing-container32 {
  flex: 0 0 auto;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  padding-left: 20px;
  padding-right: 20px;
  justify-content: center;
  background-color: var(--dl-color-gray-white);
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.pricing-container33 {
  flex: 0 0 auto;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  padding-left: 20px;
  padding-right: 20px;
  justify-content: center;
  background-color: var(--dl-color-gray-white);
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.pricing-container34 {
  flex: 0 0 auto;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  padding-left: 20px;
  padding-right: 20px;
  justify-content: center;
  background-color: var(--dl-color-gray-white);
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.pricing-container35 {
  flex: 0 0 auto;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  background-color: var(--dl-color-primary-hell);
}
.pricing-container36 {
  flex: 0 0 auto;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  padding-left: 20px;
  padding-right: 20px;
  justify-content: center;
  background-color: var(--dl-color-gray-white);
  border-top-width: 1px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.pricing-icon {
  fill: var(--dl-color-primary-lila);
  width: 24px;
  height: 24px;
}
.pricing-container37 {
  flex: 0 0 auto;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  padding-left: 20px;
  padding-right: 20px;
  justify-content: center;
  background-color: var(--dl-color-gray-white);
  border-top-width: 1px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.pricing-text32 {
  opacity: 0.5;
  font-size: 30px;
  font-style: normal;
  font-weight: 300;
}
.pricing-container38 {
  flex: 0 0 auto;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  padding-left: 20px;
  padding-right: 20px;
  justify-content: center;
  background-color: var(--dl-color-gray-white);
  border-top-width: 1px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.pricing-text33 {
  opacity: 0.5;
  font-size: 30px;
  font-style: normal;
  font-weight: 300;
}
.pricing-container39 {
  flex: 1;
  width: 250px;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  flex-direction: column;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 1px;
  border-bottom-width: 0px;
}
.pricing-container40 {
  width: 100%;
  height: 340px;
  display: flex;
  align-items: center;
  padding-top: 40px;
  padding-left: 20px;
  padding-right: 20px;
  flex-direction: column;
  padding-bottom: 40px;
  justify-content: space-between;
}
.pricing-text34 {
  font-weight: 600;
}
.pricing-container41 {
  gap: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.pricing-container42 {
  gap: 2px;
  display: flex;
  align-items: flex-end;
}
.pricing-text38 {
  fill: var(--dl-color-gray-700);
  color: var(--dl-color-gray-700);
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 1.8;
}
.pricing-text39 {
  fill: var(--dl-color-gray-700);
  color: var(--dl-color-gray-700);
  line-height: 2.3;
}
.pricing-text40 {
  text-align: center;
}
.pricing-link1 {
  display: contents;
}
.pricing-container43 {
  flex: 0 0 auto;
  width: auto;
  cursor: poi;
  height: 40px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  padding-left: 20px;
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: 20px;
  justify-content: center;
  text-decoration: none;
}
.pricing-container44 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.pricing-container45 {
  flex: 0 0 auto;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  padding-left: 20px;
  padding-right: 20px;
  background-color: var(--dl-color-primary-hell);
  border-left-width: 0px;
  border-right-width: 0px;
}
.pricing-container46 {
  flex: 0 0 auto;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  padding-left: 20px;
  padding-right: 20px;
  justify-content: center;
  background-color: var(--dl-color-gray-white);
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.pricing-container47 {
  flex: 0 0 auto;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  padding-left: 20px;
  padding-right: 20px;
  justify-content: center;
  background-color: var(--dl-color-gray-white);
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.pricing-container48 {
  flex: 0 0 auto;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  padding-left: 20px;
  padding-right: 20px;
  justify-content: center;
  background-color: var(--dl-color-gray-white);
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.pricing-container49 {
  flex: 0 0 auto;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  background-color: var(--dl-color-primary-hell);
}
.pricing-container50 {
  flex: 0 0 auto;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  padding-left: 20px;
  padding-right: 20px;
  justify-content: center;
  background-color: var(--dl-color-gray-white);
  border-top-width: 1px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.pricing-icon02 {
  fill: var(--dl-color-primary-lila);
  width: 24px;
  height: 24px;
}
.pricing-container51 {
  flex: 0 0 auto;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  padding-left: 20px;
  padding-right: 20px;
  justify-content: center;
  background-color: var(--dl-color-gray-white);
  border-top-width: 1px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.pricing-icon04 {
  fill: var(--dl-color-primary-lila);
  width: 24px;
  height: 24px;
}
.pricing-container52 {
  flex: 0 0 auto;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  padding-left: 20px;
  padding-right: 20px;
  justify-content: center;
  background-color: var(--dl-color-gray-white);
  border-top-width: 1px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.pricing-icon06 {
  fill: var(--dl-color-primary-lila);
  width: 24px;
  height: 24px;
}
.pricing-container53 {
  flex: 1;
  width: 250px;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  flex-direction: column;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 1px;
  border-bottom-width: 0px;
}
.pricing-container54 {
  width: 100%;
  height: 340px;
  display: flex;
  align-items: center;
  padding-top: 40px;
  padding-left: 20px;
  padding-right: 20px;
  flex-direction: column;
  padding-bottom: 40px;
  justify-content: space-between;
}
.pricing-container55 {
  gap: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.pricing-container56 {
  gap: 2px;
  display: flex;
  align-items: flex-end;
}
.pricing-text53 {
  fill: var(--dl-color-gray-700);
  color: var(--dl-color-gray-700);
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 1.8;
}
.pricing-text54 {
  fill: var(--dl-color-gray-700);
  color: var(--dl-color-gray-700);
  line-height: 2.3;
}
.pricing-text55 {
  text-align: center;
}
.pricing-link2 {
  display: contents;
}
.pricing-container57 {
  flex: 0 0 auto;
  width: auto;
  cursor: poi;
  height: 40px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  padding-left: 20px;
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: 20px;
  justify-content: center;
  text-decoration: none;
}
.pricing-container58 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.pricing-container59 {
  flex: 0 0 auto;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  padding-left: 20px;
  padding-right: 20px;
  background-color: var(--dl-color-primary-hell);
  border-left-width: 0px;
  border-right-width: 0px;
}
.pricing-container60 {
  flex: 0 0 auto;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  padding-left: 20px;
  padding-right: 20px;
  justify-content: center;
  background-color: var(--dl-color-gray-white);
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.pricing-container61 {
  flex: 0 0 auto;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  padding-left: 20px;
  padding-right: 20px;
  justify-content: center;
  background-color: var(--dl-color-gray-white);
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.pricing-container62 {
  flex: 0 0 auto;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  padding-left: 20px;
  padding-right: 20px;
  justify-content: center;
  background-color: var(--dl-color-gray-white);
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.pricing-container63 {
  flex: 0 0 auto;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  background-color: var(--dl-color-primary-hell);
}
.pricing-container64 {
  flex: 0 0 auto;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  padding-left: 20px;
  padding-right: 20px;
  justify-content: center;
  background-color: var(--dl-color-gray-white);
  border-top-width: 1px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.pricing-icon08 {
  fill: var(--dl-color-primary-lila);
  width: 24px;
  height: 24px;
}
.pricing-container65 {
  flex: 0 0 auto;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  padding-left: 20px;
  padding-right: 20px;
  justify-content: center;
  background-color: var(--dl-color-gray-white);
  border-top-width: 1px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.pricing-icon10 {
  fill: var(--dl-color-primary-lila);
  width: 24px;
  height: 24px;
}
.pricing-container66 {
  flex: 0 0 auto;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  padding-left: 20px;
  padding-right: 20px;
  justify-content: center;
  background-color: var(--dl-color-gray-white);
  border-top-width: 1px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.pricing-icon12 {
  fill: var(--dl-color-primary-lila);
  width: 24px;
  height: 24px;
}
.pricing-container67 {
  flex: 1;
  width: 250px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.pricing-container68 {
  width: 100%;
  height: 340px;
  display: flex;
  align-items: center;
  padding-top: 40px;
  padding-left: 20px;
  padding-right: 20px;
  flex-direction: column;
  padding-bottom: 40px;
  justify-content: space-between;
}
.pricing-container69 {
  gap: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.pricing-container70 {
  gap: 2px;
  display: flex;
  align-items: flex-end;
}
.pricing-text68 {
  fill: var(--dl-color-gray-700);
  color: var(--dl-color-gray-700);
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 1.8;
}
.pricing-text69 {
  fill: var(--dl-color-gray-700);
  color: var(--dl-color-gray-700);
  line-height: 2.3;
}
.pricing-text70 {
  text-align: center;
}
.pricing-link3 {
  display: contents;
}
.pricing-container71 {
  flex: 0 0 auto;
  width: auto;
  cursor: poi;
  height: 40px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  padding-left: 20px;
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: 20px;
  justify-content: center;
  text-decoration: none;
}
.pricing-container72 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.pricing-container73 {
  flex: 0 0 auto;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  padding-left: 20px;
  padding-right: 20px;
  background-color: var(--dl-color-primary-hell);
  border-left-width: 0px;
  border-right-width: 0px;
}
.pricing-container74 {
  flex: 0 0 auto;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  padding-left: 20px;
  padding-right: 20px;
  justify-content: center;
  background-color: var(--dl-color-gray-white);
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.pricing-container75 {
  flex: 0 0 auto;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  padding-left: 20px;
  padding-right: 20px;
  justify-content: center;
  background-color: var(--dl-color-gray-white);
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.pricing-container76 {
  flex: 0 0 auto;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  padding-left: 20px;
  padding-right: 20px;
  justify-content: center;
  background-color: var(--dl-color-gray-white);
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.pricing-container77 {
  flex: 0 0 auto;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  background-color: var(--dl-color-primary-hell);
}
.pricing-container78 {
  flex: 0 0 auto;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  padding-left: 20px;
  padding-right: 20px;
  justify-content: center;
  background-color: var(--dl-color-gray-white);
  border-top-width: 1px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.pricing-icon14 {
  fill: var(--dl-color-primary-lila);
  width: 24px;
  height: 24px;
}
.pricing-container79 {
  flex: 0 0 auto;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  padding-left: 20px;
  padding-right: 20px;
  justify-content: center;
  background-color: var(--dl-color-gray-white);
  border-top-width: 1px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.pricing-icon16 {
  fill: var(--dl-color-primary-lila);
  width: 24px;
  height: 24px;
}
.pricing-container80 {
  flex: 0 0 auto;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  padding-left: 20px;
  padding-right: 20px;
  justify-content: center;
  background-color: var(--dl-color-gray-white);
  border-top-width: 1px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.pricing-icon18 {
  fill: var(--dl-color-primary-lila);
  width: 24px;
  height: 24px;
}
